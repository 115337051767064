body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div#root,
body,
html {
  height: 100%;
  background: white;
}

/*.MuiButton-root.signinButton {*/
/*  font-size: 1rem!important;*/
/*}*/

/*.MuiButton-root.signupButton {*/
/*  padding: 16px!important;*/
/*  font-size: 1rem!important;*/
/*}*/

.MuiButton-root {
  /*padding: 10px 30px!important;*/
  border-radius: 0 !important;
}
.MuiButton-label {
  text-transform: none;
  font-size: 16px;
}
.MuiButton-containedPrimary:hover {
  background-color: #ef2828 !important;
}
.MuiButton-outlinedPrimary {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.MuiButton-outlinedPrimary:hover {
  border: 0 !important;
  background-color: black !important;
  color: white !important;
}

.MuiList-root {
  display: flex;
}
.MuiPaper-root .MuiList-root {
  flex-direction: column;
}

.paperContent {
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  line-height: 24px;
}

a {
  color: #111;
  font-weight: 600;
}